export default function heartsInit() {
    const items = Array.from(document.querySelectorAll<HTMLElement>('.js-for-who-list-item'));
    const hearts = Array.from(document.querySelectorAll<SVGPathElement>('.js-heart'));

    const heartsDefaultColor = hearts[0].getAttribute('fill');

    items.forEach((item, i) => {
        item.addEventListener('mouseenter', () => {
            const { color } = item.dataset;
            if (color) {
                hearts[i].setAttribute('fill', color);
            }
        });
        item.addEventListener('mouseleave', () => {
            if (heartsDefaultColor) {
                hearts[i].setAttribute('fill', heartsDefaultColor);
            }
        });
    });
}
