export const initFlampArrow = () => {
    const text = document.querySelector<HTMLElement>('.js-flamp-arrow-text');
    const arrow = document.querySelector<HTMLElement>('.js-flamp-arrow');
    const button = document.querySelector<HTMLElement>('.js-flamp-button');

    if (text && arrow && button) {
        const textWidth = text.offsetWidth;
        arrow.style.setProperty('--offset', `${textWidth}px`);
        button.style.setProperty('--width', `${textWidth - 10}px`);
    }
};
