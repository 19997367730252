import createFormSender from '../../modules/ajax-form-sender';
import createValidator from '../../modules/validator';
import type { CustomResponse, CustomError } from '../types';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<
    HTMLFormElement,
    {
        inputs: HTMLInputElement[];
        sender: Record<string, any>;
        submitFn: (event: Event) => void;
    }
>();

const showSuccessMessage = (form: HTMLFormElement, message = '') => {
    const successMessage = form.querySelector('.js-form-message__success');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = false;
    }

    if (failureBlock) {
        failureBlock.hidden = true;
    }

    if (successMessage) {
        successMessage.textContent = message;
    }
};

const showFailureMessage = (form: HTMLFormElement, message = '') => {
    const failureMessage = form.querySelector('.js-form-message__failure');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    if (successBlock) {
        successBlock.hidden = true;
    }

    if (failureBlock) {
        failureBlock.hidden = false;
    }

    if (failureMessage) {
        failureMessage.textContent = message;
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    messagesContainer?.classList.remove('form-messages--hidden');
};

const hideFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    const successBlock = form.querySelector<HTMLElement>('.js-form-message--success');
    const failureBlock = form.querySelector<HTMLElement>('.js-form-message--failure');

    messagesContainer?.classList.add('form-messages--hidden');

    setTimeout(() => {
        if (successBlock) {
            successBlock.hidden = true;
        }

        if (failureBlock) {
            failureBlock.hidden = true;
        }
    }, 300);
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');
    if (checkInput) {
        checkInput.value = '';
    }
};

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));
    forms.forEach((form) => {
        let isSubmitting = false;

        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'end',
            },
        });

        const sender = createFormSender(form, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            shouldClearInputs: true,
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: (response: CustomResponse) => {
                if (response.success) {
                    if (response.data && response.data.message) {
                        showSuccessMessage(form, response.data.message);
                    } else {
                        showSuccessMessage(form, 'С вами свяжутся ближайшее время ');
                    }
                } else if (response.data && response.data.message) {
                    showFailureMessage(form, response.data.message);
                } else {
                    showFailureMessage(form, 'Произошла ошибка');
                }
            },
            onError: (response: CustomResponse) => {
                if (response.errors && response.errors.length > 0) {
                    let str = '';
                    response.errors.forEach((error: CustomError) => {
                        str += `${error.message}; `;
                    });
                    showFailureMessage(form, str);
                }
            },
            onComplete: () => {
                showFormMessages(form);
                setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        function submitFn(event: Event) {
            if (isSubmitting) return;
            event.preventDefault();

            let timer: NodeJS.Timeout;
            const isFormValid = validator.validate();

            if (isFormValid) {
                isSubmitting = true;

                timer = setTimeout(() => {
                    form.classList.add('is-load');
                }, 300);
                form.classList.add('is-overlay');

                sender.send().finally(() => {
                    isSubmitting = false;
                    clearTimeout(timer);
                    form.classList.remove('is-load');
                    form.classList.remove('is-overlay');
                });
            }
        }

        form.addEventListener('submit', submitFn);
        map.set(form, { inputs: validator.inputs, sender, submitFn });
    });
}

function destroy(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));

    forms.forEach((form) => {
        const data = map.get(form);

        if (data) {
            form.removeEventListener('submit', data.submitFn);
        }
    });
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
