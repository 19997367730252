import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';
import 'swiper/css';
import masks from './components/masks';
import forms from './components/ajax-forms';
import hideHeaderOnScrollInit from './components/header-scroll-observe';
import { lazyload } from './components/lazyload';

import { feedbackSliderInit } from './components/feedback-slider';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import initBurger from './components/burger';
import heartsInit from './components/hearts';
import { initFlampArrow } from './components/flamp-arrow';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    // Code here
    calculateScrollbarWidth();
    feedbackSliderInit();
    masks.init();
    forms.init();
    hideHeaderOnScrollInit();
    lazyload.update();
    initBurger();
    heartsInit();
    initFlampArrow();
});

// Always at the end
// module.hot?.accept();
