export default function initBurger() {
    const burgerLinksWrapper = document.querySelector('.js-burger-links');
    const burgerLinks = Array.from(document.querySelectorAll('.js-burger-copy-link'));
    const burgerPopup = document.querySelector('app-lit-popup[data-lit-popup="burger"]');
    if (burgerLinksWrapper && burgerLinks.length > 0 && burgerPopup) {
        burgerLinks.forEach((link) => {
            const copy = link.cloneNode(true) as HTMLElement;
            copy.addEventListener('click', () => {
                burgerPopup.close();
            });
            burgerLinksWrapper.appendChild(copy);
        });
    }
}
