import Swiper, { Navigation } from 'swiper';

export const feedbackSliderInit = () => {
    const sliderEl = document.querySelector<HTMLElement>('.js-feedback-slider');
    const prevButton = document.querySelector<HTMLElement>('.js-feedback-slider-prev');
    const nextButton = document.querySelector<HTMLElement>('.js-feedback-slider-next');

    if (sliderEl) {
        if (prevButton && nextButton) {
            const sliderRect = sliderEl.getBoundingClientRect();
            const sizeOfButton = prevButton.offsetWidth;
            if (window.matchMedia('(min-width: 1280px)').matches) {
                prevButton.style.left = `${sliderRect.left - sizeOfButton - 40}px`;
                nextButton.style.left = `${sliderRect.right + 14}px`;
            } else {
                prevButton.style.left = `${sliderRect.left - sizeOfButton + 24}px`;
                nextButton.style.left = `${sliderRect.right - 24}px`;
            }
        }
        new Swiper(sliderEl, {
            modules: [Navigation],
            loop: true,
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton,
            },
            grabCursor: true,
            watchSlidesProgress: true,
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                200: {
                    slidesPerView: 1,
                    spaceBetween: 36,
                },
            },
        });
    }
};
